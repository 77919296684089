import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import {Vue as VueIntegration} from '@sentry/integrations/dist/vue';
import {Integrations} from '@sentry/tracing';

export default class pwiSentry {
    static init() {
        /** Sentry Error Reporting **/
        console.log('process.env.MIX_APP_ENV: ', process.env.MIX_APP_ENV);
        if (process.env.MIX_APP_ENV === 'production') {
            Sentry.init({
                environment: process.env.MIX_APP_ENV,
                dsn: "https://5c5b2ce82c00409fb4b5b986b1bef21b@o479463.ingest.sentry.io/5524463",
                integrations: integrations => [
                    new VueIntegration({
                        Vue,
                        tracing: true,
                    }),
                    new Integrations.BrowserTracing(),
                ],

                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 0.5,
                logErrors: false,
            });
        }
    }
}