window.$ = window.jQuery = require('jquery');
require('bootstrap-sass');
require('jquery-ui');
require('jquery-colorbox');
var Blazy = require('blazy');
var mapLoaded = false;

require('./bootstrap');

/** Overriding some JS prototypes **/
String.prototype.stripSlashes = function() {
    return this.replace(/\\(.)/gm, '$1');
};

Number.prototype.formatMoney = function(decPlaces, thouSeparator, decSeparator) {
    var n = this,
        decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
        decSeparator = decSeparator == undefined ? '.' : decSeparator,
        thouSeparator = thouSeparator == undefined ? ',' : thouSeparator,
        sign = n < 0 ? '-' : '',
        i = parseInt((n = Math.abs(+n || 0).toFixed(decPlaces))) + '',
        j = (j = i.length) > 3 ? j % 3 : 0;
    return (
        sign +
        (j ? i.substr(0, j) + thouSeparator : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
        (decPlaces
            ? decSeparator +
              Math.abs(n - i)
                  .toFixed(decPlaces)
                  .slice(2)
            : '')
    );
};

Number.prototype.parseFileSize = function() {
    var bytes = this;
    var kilobyte = 1024;
    var megabyte = kilobyte * 1024;
    var gigabyte = megabyte * 1024;

    if (bytes >= 0 && bytes < kilobyte) {
        return bytes + 'B';
    } else if (bytes >= kilobyte && bytes < megabyte) {
        return (bytes / kilobyte).toFixed(2) + 'KB';
    } else {
        return (bytes / megabyte).toFixed(2) + 'MB';
    }
};

window.initializeMapWhenInViewport = function() {
    if (!mapLoaded) {
        var map = document.getElementById('map');
        if (map && elementIsInViewport(map)) {
            initializeMap();
            mapLoaded = true;
        }
    }
};

window.elementIsInViewport = function(element) {
    var top = element.offsetTop;
    var left = element.offsetLeft;
    var width = element.offsetWidth;
    var height = element.offsetHeight;

    while (element.offsetParent) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
    }

    return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
    );
};

window.initializeMap = function() {
    //console.log('initializing map...');
    if ($('.area-list').length > 0) {
        /* Impact Areas Map */
        var bounds = new google.maps.LatLngBounds();
        var infowindow = new google.maps.InfoWindow();
        var markers = new Array();
        var infoWindows = new Array();
        var lastInfoWindowOpened;
        var countries = new Array();

        if ($('.area-list .row').length > 0) {
            $('.area-list > .row').each(function() {
                var obj = new Object();
                obj.name = $(this)
                    .find('.country-name')
                    .text();
                obj.lat = parseFloat(
                    $(this)
                        .find('input[name=lat]')
                        .val()
                );
                obj.lng = parseFloat(
                    $(this)
                        .find('input[name=lng]')
                        .val()
                );
                obj.alias = $(this)
                    .find('input[name=alias]')
                    .val();
                countries.push(obj);
            });

            var mapCanvas = document.getElementById('map');
            var mapOptions = {
                zoom: 1,
                center: new google.maps.LatLng(countries[0].lat, countries[0].lng),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: styles,
                animatedZoom: true,
                streetViewControl: false,
                disableDefaultUI: true,
            };

            var map = new google.maps.Map(mapCanvas, mapOptions);

            map.addListener('mouseover', function() {
                map.setOptions({ disableDefaultUI: false });
            });

            map.addListener('mouseout', function() {
                map.setOptions({ disableDefaultUI: true });
            });

            if (countries.length == 1) {
                var iWindow = new google.maps.InfoWindow({
                    content:
                        '<b>' +
                        countries[0].name +
                        "</b><br /><a href='/country/" +
                        countries[0].alias +
                        "'>View Country</a>",
                });

                var marker = new google.maps.Marker({
                    position: { lat: countries[0].lat, lng: countries[0].lng },
                    map: map,
                    animation: google.maps.Animation.DROP,
                    title: countries[0].name,
                    infowindow: iWindow,
                });

                google.maps.event.addListener(marker, 'click', function() {
                    this.infowindow.open(map, this);
                });
            } else {
                for (var x in countries) {
                    var iWindow = new google.maps.InfoWindow({
                        content:
                            '<b>' +
                            countries[x].name +
                            "</b><br /><a href='/country/" +
                            countries[x].alias +
                            "'>View Country</a>",
                    });

                    markers.push(
                        new google.maps.Marker({
                            position: { lat: countries[x].lat, lng: countries[x].lng },
                            map: map,
                            animation: google.maps.Animation.DROP,
                            title: countries[x].name,
                            infowindow: iWindow,
                        })
                    );
                }

                for (var i in markers) {
                    google.maps.event.addListener(markers[i], 'click', function() {
                        if (typeof lastInfoWindowOpened != 'undefined') {
                            lastInfoWindowOpened.close();
                        }

                        lastInfoWindowOpened = this.infowindow;

                        this.infowindow.open(map, this);
                    });

                    bounds.extend(markers[i].position);

                    map.fitBounds(bounds);

                    var initialZoom = 1;
                }
            }
        }
    }
};

window.imageResizeBinder = function() {
    let $resizeModal = $('#imageResizerModal');
    let $uploadCrop = $resizeModal.find('img#cropperImage');

    let callback;
    let aspectRatio = '';
    let finalWidth = '';
    let finalHeight = '';
    let imageIsFor = '';
    let cropBoxResizable = false;

    let movable = false;
    let zoomable = false;
    let scalable = false;

    // Reads the file from given input and loads it into Croppie preview
    function cropperReadFile(input) {
        $uploadCrop.attr('src', '');
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function(event) {
                $uploadCrop.attr('src', event.target.result);

                // If the user canceled the modal, and then picked another image
                // the image in the cropper would be the previous.  Destroying the
                // cropper should fix this.
                $uploadCrop.cropper('destroy');

                $uploadCrop.cropper({
                    aspectRatio: aspectRatio,
                    cropBoxResizable: cropBoxResizable,
                    toggleDragModeOnDblclick: false,
                    dragMode: 'none',
                    minCropBoxWidth: finalWidth,
                    minCropBoxHeight: finalHeight,
                    movable: movable,
                    zoomable: zoomable,
                    scalable: scalable,
                    crop: function(e) {},
                    minContainerWidth: 400,
                    minContainerHeight: 300,
                    responsive: true,
                });
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    $('#imageResizerModal').on('hidden.bs.modal', function() {
        $(usedFileInput).val('');
    });

    let usedFileInput = '';

    $('input.bindToImageResizeModal[type=file]')
        .unbind()
        .on('change', function(event) {
            if (this.files[0].size <= 10000000) {
                usedFileInput = $(this);

                let passedAspectRatio = $(this).data('aspect-ratio');
                if (passedAspectRatio != '') {
                    aspectRatio = passedAspectRatio;
                }

                let passedFinalWidth = $(this).data('final-width');
                if (passedFinalWidth != '') {
                    finalWidth = passedFinalWidth;
                }

                let passedFinalHeight = $(this).data('final-height');
                if (passedFinalHeight != '') {
                    finalHeight = passedFinalHeight;
                }

                let passedCropBoxResizable = $(this).data('cropboxresizable');
                if (typeof passedCropBoxResizable != 'undefined') {
                    cropBoxResizable = passedCropBoxResizable;
                }

                let passedMovable = $(this).data('movable');
                if (typeof passedMovable != 'undefined') {
                    movable = passedMovable;
                }

                let passedZoomable = $(this).data('zoomable');
                if (typeof passedZoomable != 'undefined') {
                    zoomable = passedZoomable;
                }

                let passedScalable = $(this).data('scalable');
                if (typeof passedScalable != 'undefined') {
                    scalable = passedScalable;
                }

                imageIsFor = $(this).data('image-is-for');

                if (!imageIsFor) {
                    swal({
                        title: 'Oops!',
                        text: 'Something went wrong, please refresh the page and try again.',
                        type: 'error',
                        showCancelButton: false,
                        confirmButtonClass: 'btn-danger',
                        confirmButtonText: 'OK',
                        closeOnConfirm: true,
                    });
                }

                console.log('imageIsFor: ' + imageIsFor);

                console.log('callback: ' + $(event.target).data('callback'));

                console.log('this: ' + this);

                // Set the callback
                callback = window[$(event.target).data('callback')].bind(this);

                // Show the image cropping modal
                $resizeModal.modal('show');

                // Read the file and add it to Croppie preview
                cropperReadFile(this);
            } else {
                swal({
                    title: 'Oops!',
                    text: "The image you're uploading is more than 10MB - please try again with an image under 10MB.",
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonClass: 'btn-danger',
                    confirmButtonText: 'OK',
                    closeOnConfirm: true,
                });
            }
        });

    // User saves the cropped photo
    $('#new-saveChanges')
        .unbind()
        .on('click', function() {
            console.log('new-saveChanges');

            let fullPath = usedFileInput.val();
            if (fullPath) {
                let startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/');
                let filename = fullPath.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }
            }

            let re = /(?:\.([^.]+))?$/;

            let ext = re.exec(filename)[1];

            let fileType = '';

            switch (ext.toLowerCase()) {
                case 'png':
                    fileType = 'image/png';
                    break;
                case 'jpg':
                case 'jpeg':
                    fileType = 'image/jpeg';
                    break;
            }

            // Render the cropped image
            let croppedImage = $uploadCrop
                .cropper('getCroppedCanvas', {
                    beforeDrawImage: function(canvas) {
                        const context = canvas.getContext('2d');
                        context.fillColor = '#fff';
                        context.imageSmoothingEnabled = false;
                        context.imageSmoothingQuality = 'high';
                    },
                })
                .toDataURL(fileType);

            let token = $(document)
                .find('input[name=_token]')
                .val();

            let caption = $(document)
                .find('#caption')
                .val();

            let formValues = new FormData();

            formValues.append('_token', token);
            formValues.append('filename', filename);
            formValues.append('imageData', croppedImage);
            formValues.append('imageExt', ext);
            formValues.append('fileType', fileType);
            formValues.append('imageIsFor', imageIsFor);
            formValues.append('caption', caption);

            console.log('imageIsFor: ' + imageIsFor);

            if (!imageIsFor) {
                swal({
                    title: 'Oops!',
                    text: 'Something went wrong, please refresh the page and try again.',
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonClass: 'btn-danger',
                    confirmButtonText: 'OK',
                    closeOnConfirm: true,
                });
            }

            $.ajax({
                url: '{{ route("imageUploader") }}',
                data: formValues,
                processData: false,
                contentType: false,
                type: 'POST',
                dataType: 'text',
            })
                .done(function(response) {
                    if (response) {
                        callback(response);
                    } else {
                        swal({
                            title: 'Oops!',
                            text: 'Something went wrong. Try refreshing the page and uploading your image again.',
                            type: 'error',
                            showCancelButton: false,
                            confirmButtonClass: 'btn-danger',
                            confirmButtonText: 'OK',
                            closeOnConfirm: true,
                        });
                    }
                })
                .fail(function(response) {
                    console.log('failed.');
                    console.log(response);
                })
                .always(function() {});

            // Hide the resize modal
            $resizeModal.modal('hide');
        });
};

$(document).ready(function() {
    var loading = false;
    var finishedMore = false;

    /*adjust all the modals for full page height vs. full screen height*/

    $('.modal').height($(window).height());

    $(document).on({
        keyup: function(e) {
            if (e.keyCode == 27) {
                // 27 = ESC
                $('.modal').hide();
                $('.share').popover('hide');
                $('.signin-action').popover('hide');
                $('.join-action').popover('hide');
            }
        },
    });

    //Fix for double clicking popover after hide
    $('body').on('hidden.bs.popover', function(e) {
        $(e.target).data('bs.popover').inState = { click: false, hover: false, focus: false };
    });

    /** Actions for pull-down **/
    $('.searchby').on('click', function() {
        if ($(this).hasClass('country')) {
            $('.exit-pull-down').trigger('click');

            setTimeout(function() {
                $('.openCountryModal').trigger('click');
            }, 1000);
        } else if ($(this).hasClass('cause')) {
            $('.exit-pull-down').trigger('click');
            setTimeout(function() {
                $('.openCauseModal').trigger('click');
            }, 1000);
        } else if ($(this).hasClass('learnmore')) {
            location.href = '/pricing';
        }
    });

    $('.browse-by')
        .find('.browseCause')
        .on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            $('.openCauseModal').trigger('click');
        });

    /** Action for footer PWI to go to marketing site **/
    $('.pwi-footer-action').on('click', function() {
        window.open('http://marketing.projectworldimpact.com');
    });

    //Action for Shipping Address Selection
    $('select[name=userAddresses]').on('change', function() {
        var addressSelected = $(this)
            .find(':selected')
            .val();

        if (addressSelected == 0) {
            $('input[name=billingAddress1]').val('');
            $('input[name=billingAddress2]').val('');
            $('input[name=billingCity]').val('');
            $('select[name=billingState] option').each(function(i) {
                if (i > 0) {
                    $(this).remove();
                }
            });
            $('input[name=billingZip]').val('');
            $('select[name=billingCountry]>option:eq(0)').prop('selected', true);
        } else {
            var addressData = addressSelected.split('|');

            $('input[name=billingAddress1]').val(addressData[2]);
            $('input[name=billingAddress2]').val(addressData[3]);
            $('input[name=billingCity]').val(addressData[4]);

            // Set the hidden input for stateId to the id saved in the database, let
            // the change method of the country be fired, and then check
            // the value of the hidden stateId input, and select that value.
            $('input[name=stateId]').val(addressData[5]);

            $('input[name=billingZip]').val(addressData[6]);

            $('select[name=billingCountry]')
                .find('option[value=' + addressData[7] + ']')
                .prop('selected', true);

            if (addressData[5] == '') {
            }
            $('select[name=billingCountry]').trigger('change');

            setTimeout(function() {
                $('select[name=billingState]')
                    .find('option[value=' + addressData[5] + ']')
                    .prop('selected', true);
            }, 1000);
        }
    });

    /** AJAX action to retrieve States Billing **/
    $(document).on(
        'change',
        'select[name=billingCountry], select[name=shippingCountry], select[name=org-country], select[name=country]',
        function() {
            var $dd = $(this);
            var dropdown = $dd.attr('name');
            var countryName = $dd.find('option:selected').text();
            var selectedItem = $(this)
                .find('option:selected')
                .val();
            var stateList = '';

            if (dropdown == 'billingCountry') {
                stateList = 'billingState';
            } else if (dropdown == 'shippingCountry') {
                stateList = 'shippingState';
            } else if (dropdown == 'country') {
                stateList = 'state';
            } else {
                stateList = 'org-state';
            }

            $.ajax({
                method: 'get',
                url: '/getStates',
                beforeSend: function() {
                    $('select[name=' + stateList + ']').empty();

                    $('select[name=' + stateList + ']').append("<option value='0'></option>");

                    $('select[name=' + stateList + ']')
                        .find('option:first')
                        .text('');
                    $('select[name=' + stateList + ']')
                        .find('option:first')
                        .text('Retrieving States for ' + countryName);
                    $('select[name=' + stateList + ']')
                        .find('option:first')
                        .val('');
                },
                data: 'id=' + selectedItem,
                dataType: 'json',
                success: function(resp) {
                    var selectedStateId = $('#stateId').val();

                    if (resp.length > 0) {
                        $('select[name=' + stateList + ']')
                            .find('option:first')
                            .text('Select a State');

                        for (var x in resp) {
                            $('select[name=' + stateList + ']').append(
                                "<option value='" + resp[x].state_id + "'>" + resp[x].state_name + '</option>'
                            );
                        }

                        $('select[name=' + stateList + ']').val(selectedStateId);
                    } else {
                        $('select[name=' + stateList + ']')
                            .find('option:first')
                            .text('Select a Country to Retrieve States');

                        // $("select[name=" + stateList + "]").find("option:first").text( "No State Required");
                        // $("select[name=" + stateList + "]").find("option:first").val( "No State");
                    }
                },
            });
        }
    );

    /** Change the cause icon mouseover state **/
    $('.overlay-cause-item').on({
        mouseenter: function() {
            var $icon = $(this).find('i');
            var $text = $(this).find('.cause-link');
            var $viewSub = $(this).find('.view-subcauses');

            var iconClass = $icon.attr('class');

            $icon
                .addClass(iconClass.replace('-stroke', '-solid'))
                .removeClass(iconClass)
                .css('color', '#f1657f');

            $text.css('color', '#f1657f');

            $viewSub.show();
        },
        mouseleave: function() {
            var $icon = $(this).find('i');
            var $text = $(this).find('.cause-link');
            var $viewSub = $(this).find('.view-subcauses');

            var iconClass = $icon.attr('class');

            $icon
                .addClass(iconClass.replace('-solid', '-stroke'))
                .removeClass(iconClass)
                .css('color', '#33aef4');

            $text.css('color', '#33aef4');

            $viewSub.hide();
        },
    });

    /** Handle subcause list? **/
    $('.overlay-subcause-list').each(function() {
        if ($(this).find('.sub-cause-list a').length < 4) {
            var size = $(this).find('.sub-cause-list a').length;

            $(this)
                .find('.sub-cause-list a')
                .each(function() {
                    $(this)
                        .parent()
                        .removeClass('col-lg-3 col-md-3 col-sm-3');

                    if (size == 1) {
                        $(this)
                            .parent()
                            .addClass('col-lg-12 col-md-12 col-sm-12');
                    } else if (size == 2) {
                        $(this)
                            .parent()
                            .addClass('col-lg-6 col-md-6 col-sm-6');
                    } else if (size == 3) {
                        $(this)
                            .parent()
                            .addClass('col-lg-4 col-md-4 col-sm-4');
                    }
                });
        }
    });

    /** Action for pulling up subcauses **/
    var $currentSubCause = null;

    $('.view-subcauses').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var causeId = $(this).data('cause-id');
        var causeName = $(this).data('cause-name');
        var $subCause = $('.overlay-subcause-list[data-parent-id=' + causeId + ']');
        $currentSubCause = $subCause;

        if ($subCause.length > 0) {
            $('.overlay-cause-list').fadeOut('slow', function() {
                $subCause
                    .find('h1')
                    .find('span')
                    .html(causeName);
                $subCause.fadeIn('slow');
            });
        }
    });

    /** Click "back to causes" button **/
    $('.overlay-subcause-list')
        .find('small')
        .on('click', function() {
            $currentSubCause.fadeOut('slow', function() {
                $('.overlay-cause-list').fadeIn('slow');
                $currentSubCause = null;
            });
        });

    /**
     * Check to see if an input with name 'initialLoad' is present.
     * This will be used for Product, Organization, Crowdfunding
     * home pages to load more results when scrolling through
     * the results.
     */

    var initialPayLoad = 0;
    var nextPayLoad = 0;

    if ($('input[name=initialLoad]').length > 0) {
        //Read inputs that are populated from controller.
        initialPayLoad = parseInt($('input[name=initialLoad]').val());

        nextPayLoad = parseInt($('input[name=nextPayLoad]').val());
        var page = $('input[name=page]').val();

        //when window scrolls and reaches designated point, call loadMore function
        $(window).on('scroll', function() {
            //Get top of footer container
            var fTop = $('footer').position().top;

            var scroll = $(window).scrollTop();
            var wHeight = $(window).height();

            if (fTop <= scroll + wHeight) {
                if (!loading && !finishedMore) {
                    loading = true;
                    loadMore();
                }
            }
        });
    }

    /** User logs out **/
    $('.logout').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        location.href = '/auth/logout';
    });

    /** Handle dropdown menu clicks **/
    $('.dropdown-menu li a').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!$(this).attr('href') == '') {
            location.href = $(this).attr('href');
        } else {
            if ($(this).attr('class') == 'openCountryModal') {
                //$("#countryModal").find(".modal-content-container").addClass( "countryModalOverride" );
            } else {
                //$("#causeModal").find(".exit-cause-modal").css({top: "-20px", right: "0px"});
                //$("#causeModal").find(".cause-container").addClass("causeModalOverride");
            }
        }
    });

    /** click event for opening the cause modal on homepage **/
    $('.openCauseModal').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        $('#causeModal').css('display', 'table');
    });

    /** click event for opening the country modal on homepage **/
    $('.openCountryModal').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        $('#countryModal').css('display', 'table');
    });
    /** click event for opening the cause modal on homepage **/
    $('.openCauseModal').on('click', function(e) {
      e.stopPropagation();
      e.preventDefault();
      $('#causeModal').css('display', 'table');
    });
    /** click event for opening the comment modal **/
    $('.openCommentModal').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();

        $('#postReviewModal').css('display', 'table');
    });

    /** click event for opening the comment modal **/
    $('.openSuggestNP').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        try {
            grecaptcha.reset();
        } catch (exception) {
            //console.error(exception);
        }

        $('.errorBinder').html('');
        $('.errorBinder').css('display', 'none');

        $('#suggestNonProfitModal').css('display', 'flex');
    });

    /** Events for the home page nav search **/
    $('.search').on('click', function() {
        // var searchTerm = $("input[name=search]").val( );
        //
        // if( searchTerm == "" ){
        toggleNavSearch();
        // } else {
        //     location.href = "/search/" + encodeURIComponent( searchTerm );
        // }
    });

    /** Event for sharing to social media **/
    $('.share').popover({
        content: $('.social-media-share').html(),
        html: true,
        placement: 'bottom',
        container: 'body',
        trigger: 'click',
        template:
            '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content social-media-share"></div></div>',
    });

    /** Click social media share link **/
    $(document).on('click', '.popover-content.social-media-share a', function(e) {
        e.stopPropagation();
        e.preventDefault();

        window.open($(this).attr('href'), '', 'height=640,width=480');
    });

    /** Event for bringing up login pop-up **/
    $('.signin-action').popover({
        content: "<div class='login-popover-wrapper'>" + $('.login-wrapper').html() + '</div>',
        html: true,
        placement: 'bottom',
        container: 'body',
        trigger: 'click',
        template:
            '<div class="popover login-popover" ><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
    });

    /** Click "Sign In" button **/
    $('.signin-action').on('click', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    });

    /** Event for bringing up join popup **/
    $('.join-action').popover({
        content: $('.join-wrapper').html(),
        html: true,
        placement: 'bottom',
        container: 'body',
        trigger: 'click',
        template:
            '<div class="popover join-popover" ><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
    });

    /** Click button to sign up as a user **/
    // $(document).on("click", ".join-user", function(  ){
    //     location.href = '/register/user';
    // });

    /** Click button to sign up as an organization **/
    $(document).on('click', '.join-org', function() {
        location.href = '/pricing';
    });

    /** Submit login credentials **/
    $(document).on('click', '.login-popover-wrapper #actionSignIn', function() {
        submitLoginForm();
    });

    $(document).on('click', '.login-wrapper #actionSignIn', function() {
        submitLoginForm();
    });

    /** Submit on enter **/
    $(document).on(
        'keyup',
        '.login-popover-wrapper input[name=email], .login-popover-wrapper input[name=password]',
        function(event) {
            if (event.keyCode === 13) {
                // 13 = Enter
                submitLoginForm();
            }
        }
    );

    /** Link to User Portal **/
    $('.toUserPortal').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $('form[name=passthru]').trigger('submit');
    }); /* ************************************ */
    /**
     * These on click social network calls were not working in IOS. You can see the inputs in views.user.login.
     * I had to change them to A tags. This means that these 3 methods may not be in use anymore.
     */ /* ************************************ */ /* ************************************ */

    /* ************************************ */ /** Action for Facebook Login Button **/
    $(document).on('click', '.login-popover-wrapper .btn-facebook', function() {
        location.href = '/auth/social/facebook';
    });

    /** Action for twitter login button **/
    $(document).on('click', '.login-popover-wrapper .btn-twitter', function() {
        location.href = '/auth/social/twitter';
    });

    /** Action for google login button **/
    $(document).on('click', '.login-popover-wrapper .btn-google', function() {
        location.href = '/auth/social/google';
    }); /* ************************************ */
    /**
     * Mouseover/leave events for mousing over causes
     * on the cause overlay
     */ /* ************************************ */ /* ************************************ */
    /* ************************************ */ $('.cause-container a').on({
        mouseover: function() {
            var cause = $(this).data('cause');

            $(this)
                .parent()
                .find('.center')
                .html(cause);
        },
        mouseleave: function() {
            $(this)
                .parent()
                .find('.center')
                .html('');
        },
    });

    var iso = '';

    /**
     * Mouseover/leave events for mousing over causes
     * on the cause overlay
     */
    $('.country-overlay-item').on({
        mouseover: function() {
            //grab the iso code from the data-iso-codeattribte
            iso = $(this)
                .data('iso-code')
                .toLowerCase();
            var text = $(this).text();

            //Add text and flag icon to the top of panels
            $('.country-overlay-name').html(text);
            $('.country-space')
                .find('.flag-icon')
                .addClass('flag-icon-' + iso);
            $('.country-space').css('visibility', 'visible');
        },
        mouseleave: function() {
            //make the text and flag dissppear
            $('.country-space').css('visibility', 'hidden');
            $('.country-overlay-name').html('');
            $('.country-space')
                .find('.flag-icon')
                .removeClass('flag-icon-' + iso);
        },
    });

    /**
     * .readmore event
     * Click event for the 'Read More' link on descriptions for
     * crowdfunding, products and organiztions
     */
    $(document).on('click', '.readmore', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(this)
            .parent()
            .find('.more')
            .show();
        $(this).hide();

        $(this)
            .parent()
            .find('.readless')
            .show();
    });

    /**
     * .readless event
     * Click event for the 'Read Less' link on descriptions for
     * crowdfunding, products and organiztions
     */
    $(document).on('click', '.readless', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(this)
            .parent()
            .find('.more')
            .hide();

        $(this).hide();

        $(this)
            .parent()
            .find('.readmore')
            .show();
    });

    /**
     * .whatis event
     * Click event for the 'what is pwi' link
     * on home page - triggers overlay
     */
    $('.whatis').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if ($('.mobile-pull-down-menu').hasClass('down')) {
            $('.navbar-toggle').trigger('click');
        }

        $('#wrapper').animate({ marginTop: '702px' }, 'slow');

        $('.pull-down-about').animate({ top: '0px' }, 'slow');
    });

    /** action to close review lightbox **/
    $('.exit').on({
        mouseenter: function() {
            $(this).css('cursor', 'pointer');
        },
        mouseleave: function() {
            $(this).css('cursor', 'auto');
        },
        click: function() {
            var el = $(this).data('control');

            $(el).hide();
        },
    });
    $('.exit-review').on({
        mouseenter: function() {
            $(this).css('cursor', 'pointer');
        },
        mouseleave: function() {
            $(this).css('cursor', 'auto');
        },
        click: function() {
            $('#postReviewModal').hide();
        },
    });

    /**
     * .pull-down-exit event
     * close the overlay from the
     * 'what is pwi' link
     */
    $('.exit-pull-down').on('click', function() {
        if (parseInt($(window).width()) <= 885) {
            $('.pull-down-about').animate({ top: '-598px' }, 'slow');
        } else {
            $('.pull-down-about').animate({ top: '-662px' }, 'slow');
        }

        $('#wrapper').animate({ marginTop: '0px' }, 'slow');
    });

    /**
     * .follow click event
     * have a user follow an org, cause, country, etc.
     */
    $('.follow').on('click', function() {
        //console.log('follow');
        var $follow = $(this);
        var data = {
            follow_item: $(this).data('type'),
            follow_item_id: $(this).data('id'),
        };
        $.ajax({
            method: 'post',
            url: '/follow',
            data: data,
            dataType: 'json',
            success: function(data) {
                if (!data.status) {
                    if (data.action == 'signin') {
                        $('.signin-action').popover('toggle');
                    }
                } else {
                    var $window = window.location.href;

                    //console.log($window);

                    // This added "if" statement is to check if the view is stories/view
                    // Because if it is, we want to make sure the html contains the p
                    // Tags because otherwise our styling will be messed up
                    // Eventually, when we begin extracting functionality
                    // We can just have a follow click event specifically for stories in stories.js
                    // Or, we can restyle the stories follow/share buttons
                    var $stories = 'stories';
                    var $view = 'view';
                    if ($window.includes($stories) && $window.includes($view)) {
                        //console.log($follow.html());
                        if ($follow.html() == '<p>follow</p>') {
                            $follow.html('<p>unfollow</p>');
                            $follow.addClass('bg-grey');
                        } else {
                            $follow.html('<p>follow</p>');
                            $follow.removeClass('bg-grey');
                        }
                    } else {
                        // Set the button text
                        $follow.html(data.action);

                        if (data.action === 'follow') {
                            $follow.removeClass('bg-grey');
                        } else {
                            $follow.addClass('bg-grey');
                        }
                    }
                }
            },
        });
    });

    /** mobile nav toggle **/
    $('.navbar-toggle').on('click', function() {
        if ($('.mobile-pull-down-menu').hasClass('down')) {
            if ($('nav.std-navbar').length > 0) {
                $('.navbar-toggle')
                    .css('background-color', '#f1f1f1')
                    .find('.icon-bar')
                    .css('background-color', '#888');
            } else {
                $('.navbar-toggle')
                    .css('background-color', '#fff')
                    .find('.icon-bar')
                    .css('background-color', '#888');
            }

            $('#browseByList').removeClass('in');

            $('.mobile-pull-down-menu')
                .find('.mobile-menu-item')
                .slideUp('slow', function() {
                    $('.mobile-pull-down-menu').removeClass('down');
                });
        } else {
            $('.mobile-pull-down-menu')
                .find('.mobile-menu-item')
                .slideDown('fast', function() {
                    $('.navbar-toggle')
                        .css('background-color', '#33aef4')
                        .find('.icon-bar')
                        .css('background-color', '#fff');

                    $('.mobile-pull-down-menu').addClass('down');
                });
        }
    });

    /** Suggested Search **/
    var xhr = null;
    var resultsJSON = null;
    var page = $('input[name=page]').val();
    var isPopOverDisplayed = false;

    $("[data-toggle='popover']").on('show.bs.popover', function() {
        isPopOverDisplayed = true;
    });
    $("[data-toggle='popover']").on('hide.bs.popover', function() {
        isPopOverDisplayed = false;
    });

    $('.navbar-form.navbar-left').submit(function(event) {
        event.preventDefault();
    });

    /**
     *
     * Below you will find the 2 different header search JS functions.  One is for the traditional header search, the other is for
     * the new Elastic Search linked header search.  You will need to comment out the one you do not want, and uncomment the method
     * you do want.  Additionally, you will have to go to app/http/controllers/SearchController and uncomment out the correct
     * corresponding methods.  They are labeled similarly to this JS.
     *
     * of course remember that you will have to run GULP.
     *
     */

    // ********************************************************************************************************************************************************************************************************************
    // ********************************************************************************************************************************************************************************************************************
    // ******* New Elastic Search Start

    // Let's restrict a leading space
    $('input[name=search]').on('keydown', function(e) {
        if (e.which === 32 && e.target.selectionStart === 0) {
            return false;
        }
    });

    /** Typing in the search bar **/
    $('input[name=search]').on('keyup', function(e) {
        e.preventDefault();
        // Let's restrict anything not a number, letter, or space
        if (!this.value.match(/[0-9a-zA-Z ]/)) {
            this.value = this.value.replace(/[^0-9a-zA-Z ]/g, '');
        }

        var el = $(this);
        var value = $(this).val();
        var popoverTitle =
            "Suggestions based on your search for '<b >" +
            value +
            "</b>'. <span class='glyphicon glyphicon-remove clear-popover pull-right exit' aria-hidden='true'></span>";

        // User hits the RETURN/ENTER key
        // if (e.keyCode === 13 && value) {
        //     window.location.href = "/search/" + encodeURIComponent(value);
        //     return;
        // }

        if (value.length > 0) {
            if (xhr) {
                if (xhr.readyState != 4) {
                    xhr.abort();
                }
            }

            el.popover({
                content: 'Searching ...',
                title: popoverTitle,
                placement: 'bottom',
                container: 'body',
                html: true,
                trigger: 'manual',
                template:
                    '<div class="popover selective-search" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
            });

            el.popover('show');
						$('.selective-search')
                            .find('.popover-title')
                            .html(popoverTitle);

            xhr = $.ajax({
                url: '/search/' + value,
                dataType: 'text',
                success: function(data) {
                    if (value.length > 0) {
                        $('.selective-search')
                            .find('.popover-content')
                            .html(data);

                        $('.clear-popover').on('click', function() {
                            $('input[name=search]').popover('hide');
                        });
                    } else {
                        el.popover('hide');
                    }
                },
            });
        } else {
            xhr.abort();
            el.popover('hide');
        }
    });

    // ******* New Elastic Search End
    // ********************************************************************************************************************************************************************************************************************
    // ********************************************************************************************************************************************************************************************************************

    // ********************************************************************************************************************************************************************************************************************
    // ********************************************************************************************************************************************************************************************************************
    // ******* Traditional Header Search Start

    //
    //
    // /** Typing in the search bar **/
    // $("input[name=search]").on("keyup", function( e ){
    //     e.preventDefault();
    //
    //     var el = $(this);
    //     var value = $(this).val( );
    //     var popoverTitle = "Suggestions based on your search for '<b >" + value + "</b>'. <span class='glyphicon glyphicon-remove clear-popover pull-right exit' aria-hidden='true'></span>";
    //
    //     // User hits the RETURN/ENTER key
    //     if (e.keyCode === 13 && value) {
    //         window.location.href = "/search/" + encodeURIComponent(value);
    //         return;
    //     }
    //
    //     if( value.length > 0 ){
    //         if( xhr ){
    //             if( xhr.readyState != 4 ){
    //                 xhr.abort( );
    //             }
    //         }
    //
    //         if ( ! isPopOverDisplayed ) {
    //             el.popover({
    //                 content: "Searching ...",
    //                 title: popoverTitle,
    //                 placement: 'bottom',
    //                 container: 'body',
    //                 html: true,
    //                 trigger: 'manual',
    //                 template: '<div class="popover selective-search" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
    //             }).popover('show');
    //         } else {
    //             $(".selective-search").find(".popover-content").html("Searching ...");
    //             $(".selective-search").find(".popover-title").html(popoverTitle);
    //         }
    //
    //         xhr = $.ajax({
    //             url: "/search/" + value,
    //             dataType: "json",
    //             success: function( data ){
    //                 if( value.length > 0 ) {
    //                     if (data.length > 0) {
    //
    //                         var divList = "<ul style='position:relative;'>";
    //                         var beginCausePattern = /^cause/;
    //                         var beginCountryPattern = /^country/;
    //
    //                         for (var x in data) {
    //                             if (beginCausePattern.test(data[x].recordtype)) {
    //                                 var end = findLink(data[x].recordtype);
    //                                 if (end == "causes") {
    //                                     divList += "<li tabindex='" + x + "'><a href='/cause/" + data[x].alias + "'>" + data[x].name.stripSlashes() + "</a></li>";
    //                                 } else {
    //                                     divList += "<li tabindex='" + x + "'><a href='/cause/" + data[x].alias + "/" + end + "'>" + data[x].name.stripSlashes() + "</a></li>";
    //                                 }
    //                             } else if (beginCountryPattern.test(data[x].recordtype)) {
    //                                 var end = findLink(data[x].recordtype);
    //
    //                                 if (end == "country") {
    //                                     divList += "<li tabindex='" + x + "' ><a href='/country/" + data[x].alias + "'>" + data[x].name.stripSlashes() + "</a></li>";
    //                                 } else {
    //                                     divList += "<li tabindex='" + x + "' ><a href='/country/" + data[x].alias + "/" + end + "'>" + data[x].name.stripSlashes() + "</a></li>";
    //                                 }
    //
    //                             } else if (data[x].recordtype == "organization") {
    //                                 divList += "<li tabindex='" + x + "'><a href='/organization/" + data[x].alias + "'>" + data[x].name.stripSlashes() + "</a></li>";
    //                             } else {
    //                                 //console.log('effed');
    //                             }
    //                         }
    //
    //                         divList += "</ul>";
    //
    //                         if ($(".selective-search").hasClass("in")) {
    //                             $(".selective-search").find(".popover-content").html("No results found!");
    //                             $(".selective-search").find(".popover-title b").html(popoverTitle);
    //                         }
    //
    //                         if ( ! isPopOverDisplayed ) {
    //                             el.popover({
    //                                 content: divList,
    //                                 title: popoverTitle,
    //                                 placement: 'bottom',
    //                                 container: 'body',
    //                                 html: true,
    //                                 trigger: 'manual',
    //                                 template: '<div class="popover selective-search" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
    //                             }).popover('show');
    //                         } else {
    //                             $(".selective-search").find(".popover-content").html(divList);
    //                             $(".selective-search").find(".popover-title").html(popoverTitle);
    //                         }
    //
    //                         $(".clear-popover").on("click", function () {
    //                             $("input[name=search]").popover('hide');
    //                         });
    //                     } else {
    //
    //                         var divList = "No results found!";
    //
    //                         if ( ! isPopOverDisplayed ) {
    //                             el.popover({
    //                                 content: divList,
    //                                 title: popoverTitle,
    //                                 placement: 'bottom',
    //                                 container: 'body',
    //                                 html: true,
    //                                 trigger: 'manual',
    //                                 template: '<div class="popover selective-search" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
    //                             }).popover('show');
    //                         } else {
    //                             $(".selective-search").find(".popover-content").html(divList);
    //                             $(".selective-search").find(".popover-title").html(popoverTitle);
    //                         }
    //                     }
    //                 } else {
    //                     el.popover('hide');
    //                 }
    //             }
    //         });
    //     } else {
    //         xhr.abort( );
    //         el.popover('hide');
    //     }
    // });

    // ******* Traditional Header Search End
    // ********************************************************************************************************************************************************************************************************************
    // ********************************************************************************************************************************************************************************************************************

    /** Actions for posting reviews **/

    var dataRating = 0;

    $('.post-comment-button').on('click', function(e) {
        $('.post-review-errors')
            .html('')
            .hide();
        var hasErrors = false;
        var id = $(this).data('id');
        var type = $(this).data('type');
        var $postParent = $(this).parents('.post-comment');
        var comment = $postParent.find('textarea[name=comment]').val();

        if (comment == '') {
            hasErrors = true;
            $('.post-review-errors').append('Please Write a comment/review.<br />');
        }

        if (dataRating == 0) {
            hasErrors = true;
            $('.post-review-errors').append('Please select a rating.<br />');
        }

        if (hasErrors) {
            $('.post-review-errors').show();
        } else {
            $.ajax({
                url: '/comment',
                method: 'POST',
                data: {
                    id: id,
                    type: type,
                    rating: dataRating,
                    comment: comment,
                },
                dataType: 'json',
            }).done(function(data) {
                $('.post-comment-button')
                    .html('post comment')
                    .attr('disabled', false);

                if (data.status) {
                    window.location.reload();
                } else {
                    swal({
                        title: 'Oops!',
                        text: 'Something went wrong.',
                        type: 'error',
                        showCancelButton: false,
                        confirmButtonClass: 'btn-danger',
                        confirmButtonText: 'OK',
                        closeOnConfirm: true,
                    });
                }
            });
        }
    });

    /** Actions for selecting a rating **/
    $('.post-comment-actions')
        .find('.rating span')
        .on('click', function() {
            dataRating = $(this).data('rating');

            for (var i = 5; i > 0; i--) {
                if (dataRating >= i) {
                    $('.post-comment-actions')
                        .find('.rating span[data-rating=' + i + ']')
                        .addClass('fill');
                } else {
                    $('.post-comment-actions')
                        .find('.rating span[data-rating=' + i + ']')
                        .removeClass('fill');
                }
            }
        });

    /** Lazy load map **/
    initializeMapWhenInViewport();
    $(document).on('scroll', initializeMapWhenInViewport);

    /** Click "Same As Shipping" **/
    $('input[name=sameAsShipping]').on('click', function() {
        if ($(this).is(':checked')) {
            $('input[name=billingAddress1]').val($('input[name=shippingAddress1]').val());
            $('input[name=billingAddress2]').val($('input[name=shippingAddress2]').val());
            $('input[name=billingCity]').val($('input[name=shippingCity]').val());
            $('input[name=billingZip]').val($('input[name=shippingZip]').val());
            $(
                'select[name=billingCountry]>option:eq(' +
                    $('select[name=shippingCountry]')
                        .find('option:selected')
                        .index() +
                    ')'
            ).prop('selected', 'true');
            $('select[name=billingCountry]').trigger('change');

            setTimeout(function() {
                $(
                    'select[name=billingState]>option:eq(' +
                        $('select[name=shippingState]')
                            .find('option:selected')
                            .index() +
                        ')'
                ).prop('selected', 'true');
            }, 3000);
        } else {
            $('input[name=billingAddress1]').val('');
            $('input[name=billingAddress2]').val('');
            $('input[name=billingCity]').val('');
            $('input[name=billingZip]').val('');
            $('select[name=billingCountry]>option:eq(0)').prop('selected', true);
            $('select[name=billingState] option').each(function(i) {
                if (i == 0) {
                    $(this).text('Select a Country to Retrieve States');
                } else {
                    $(this).remove();
                }
            });
        }
    });

    /** Typing CC Number **/
    $('input[name=cc_number]').on('keydown', function(e) {
        if ($(this).val().length == 20) {
            if (e.keyCode != 8 && e.keyCode != 37) {
                e.preventDefault();
            }
        }
    });

    /** Typing CVV Code **/
    $('input[name=ccv]').on('keydown', function(e) {
        if ($(this).val().length == 4) {
            if (e.keyCode != 8 && e.keyCode != 37) {
                e.preventDefault();
            }
        }
    });

    function isSafari() {
        var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
        return is_safari;
    }

    function isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer, return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));
        }

        return false;
    }
    function isFirefox() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    }

    /** ??? **/
    function loadMore() {
        var ajaxUrl = '';

        switch (page) {
            case 'org':
                ajaxUrl = '/org/more';
                break;
            case 'crowdfunding':
                ajaxUrl = '/crowdfunding/more';
                break;
            case 'products':
                ajaxUrl = '/products/more';
                break;
        }

        $.ajax({
            method: 'POST',
            url: ajaxUrl,
            data: {
                payload: initialPayLoad,
                next: nextPayLoad,
            },
            dataType: 'json',
            beforeSend: function() {
                $('.loadingMore').show();
            },
            success: function(data) {
                if (data.count < initialPayLoad) {
                    finishedMore = true;
                }
                switch (page) {
                    case 'org':
                        buildOrgs(data);
                        break;
                    case 'crowdfunding':
                        buildCrowdfunding(data);
                    case 'products':
                        buildProducts(data);
                        break;
                }

                loading = false;
                $('.loadingMore').hide();
                nextPayLoad += parseInt(initialPayLoad);
            },
        });
    }

    /** ??? **/
    function findLink(recordtype) {
        var endCausePattern = /causes$/;
        var endCountryPattern = /countries$/;
        var endProjectPattern = /projects$/;
        var endProductPattern = /products$/;
        var endOrgPattern = /organizations$/;

        if (endCausePattern.test(recordtype)) {
            return 'causes';
        } else if (endCountryPattern.test(recordtype)) {
            return 'country';
        } else if (endProjectPattern.test(recordtype)) {
            return 'projects';
        } else if (endProductPattern.test(recordtype)) {
            return 'products';
        } else if (endOrgPattern.test(recordtype)) {
            return 'organizations';
        }
    }

    /** ??? **/
    function buildOrgs(data) {
        var orgs = new Array();
        var orgPath = data.path;

        delete data.path;

        var html = '';

        for (var x in data) {
            html += "<div class='org-module margin-top-10'>";
            html += "   <div class='org-module-top'>";
            html += "       <div class='org-module-img-container margin-right-10 margin-bottom-5 padding-0 pull-left'>";
            html +=
                "           <a href='/organization/" +
                data[x].org_alias +
                "'><img src='" +
                data[x].logoImg +
                "' align='left'/></a>";
            html += '           <br />';
            html += "           <div class='rating below-image'>";
            for (var i = 1; i < 6; i++) {
                if (i <= data[x].rating) {
                    html += "       <span class='star fill' >";
                    html += "           <i data-icon='&#xe017;' class='pwi-icon-star pwi-icon-2em'></i>";
                    html += '       </span>';
                } else {
                    html += "       <span class='star' >";
                    html += "           <i data-icon='&#xe017;' class='pwi-icon-star pwi-icon-2em'></i>";
                    html += '       </span>';
                }
            }
            html += '           </div>';
            html += '       </div>';
            html += "       <div class='pull-left'>";
            html +=
                "           <div class='org-module-name pull-left'><a href='/organization/" +
                data[x].org_alias +
                "'>" +
                data[x].org_name.stripSlashes() +
                '</a></div>';

            if (data[x].countries.length > 0) {
                html += "               <div class='impacts-causes'>";
                html += "                   <span class='title'>Locations</span><br />";
                html += "                   <span class='list'>";
                for (var i = 0; i < data[x].countries.length; i++) {
                    if (i == 0) {
                        html += data[x].countries[i].country_name;
                    } else {
                        if (i < 6 || i > 6) {
                            html += ', ' + data[x].countries[i].country_name;
                        } else {
                            html +=
                                "<a href='' class='readmore'>...See More</a><span class='more'>," +
                                data[x].countries[i].country_name;
                        }
                    }
                }
                if (i >= 6) {
                    html += "</span><a href='#' class='readless'>Show Less</a>";
                }
                html += '                   </span>';
                html += '               </div>';
            }

            if (data[x].causes.length > 0) {
                html += "               <div class='impacts-causes'>";
                html += "                   <span class='title'>Causes</span><br />";
                html += "                   <span class='list'>";
                for (var i = 0; i < data[x].causes.length; i++) {
                    if (i == 0) {
                        html += data[x].causes[i].cause_name;
                    } else {
                        html += ', ' + data[x].causes[i].cause_name;
                    }
                }
                html += '                   </span>';
                html += '               </div>';
            }
            html += '           </div>';
            html += '       </div>';

            html += "	<div style='clear:both;'></div>";
            html += "    <div class='org-module-bottom'>";
            html += "        <div class='org-module-desc'>";

            if (typeof data[x].org_desc != 'object') {
                html += data[x].org_desc;
            }

            html += '		 </div>';
            html += '    </div>';
            html += '</div>';
        }

        $('.orgs-module').append(html);
    }

    /** ??? **/
    function buildCrowdfunding(data) {
        var projects = new Array();
        var prjPath = data.path;

        delete data.path;
        delete data.count;

        var html = '';

        for (var x in data) {
            html += "<div class='project-module'>";
            html += "   <div class='project-module-top'>";
            html +=
                "       <div class='project-module-img-container margin-right-10 margin-bottom-5 padding-0 pull-left'>";
            html +=
                "           <a href='/crowdfunding/" +
                data[x].project_alias +
                "'><img src='" +
                data[x].icon +
                "' align='left'/></a>";
            html += '        </div>';
            html += "        <div class='pull-left'>";
            html +=
                "           <div class='project-module-name pull-left'><a href='/crowdfunding/" +
                data[x].project_alias +
                "'>" +
                data[x].title +
                '</a></div>';
            html += "           <div class='project-module-org-name'>";
            html += data[x].org_name.stripSlashes();
            html += '           </div>';
            html += "           <div class='impacts-causes'>";
            html += "               <span class='title'>Locations</span><br />";
            html += "                <span class='list'>";

            for (var i = 0; i < data[x].countries.length; i++) {
                if (i == 0) {
                    html += data[x].countries[i].country_name;
                } else {
                    html += ', ' + data[x].countries[i].country_name;
                }
            }

            html += '                </span>';
            html += '           </div>';
            html += "           <div class='impacts-causes'>";
            html += "               <span class='title'>Causes</span><br />";
            html += "               <span class='list'>";

            for (var j = 0; j < data[x].causes.length; j++) {
                if (j == 0) {
                    html += data[x].causes[j].cause_name;
                } else {
                    html += ', ' + data[x].causes[j].cause_name;
                }
            }

            html += '               </span>';
            html += '           </div>';
            html += '        </div>';
            html += '   </div>';
            html += "   <div style='clear:both;'></div>";
            html += "   <div class='project-module-status'>";
            html += "       <div class='status-line'>";
            html += "           <div class='pull-left projectRaisedAmt'>";
            html += data[x].amtRaised;
            html += '           </div>';
            html += "           <div class='pull-right projectGoal'>";
            html += "               out of <span class='projectGoalAmt'>" + data[x].fundGoal + '</span>';
            html += '           </div>';
            html += '       </div>';
            html += "       <div class='status-line'>";
            html += "           <div class='progress'>";
            html +=
                "               <div class='progress-bar' role='progressbar' aria-valuenow='75' aria-valuemin='0' aria-valuemax='100' style='width: " +
                data[x].percentage +
                "%;'></div>";
            html += '           </div>';
            html += '       </div>';
            html += "       <div class='status-line'>";
            html += "           <div class='pull-left projectGoal'>";
            html += data[x].percentage + '% complete';
            html += '           </div>';
            html += "           <div class='pull-right projectGoal'>";
            html += "               <span class='projectGoalAmt'>" + data[x].daysleft + '</span> days left';
            html += '           </div>';
            html += '       </div>';
            html += '   </div>';
            html += '</div>';
        }

        $('.projects').append(html);
    }

    /** ??? **/
    function buildProducts(data) {
        var products = new Array();
        var prdPath = data.path;

        delete data.path;

        var html = '';

        for (var x in data) {
            // WHY IS THIS LOOP HERE?!
        }
    }

    /** ??? **/
    function toggleNavSearch() {
        var searchBar = $('.home-nav-search');
    
        if (searchBar.hasClass('hide-search')) {
            searchBar.removeClass('hide-search');
            $('#search').focus();
    
            // Add event listener to hide search bar when clicking outside
            $(document).on('click', function (event) {
                if (!$(event.target).closest('.home-nav-search').length) {
                    searchBar.addClass('hide-search');
                }
            });
        } else {
            searchBar.addClass('hide-search');
        }
    }
    

    // $(document).on("click", ".org-payment-gateway-form .save-gateway", function( ){

    /**
     * Submit the user login form.
     *
     * @author Luke Stanbery
     */
    function submitLoginForm() {
        //console.log("submitLoginForm");
        var $wrapper = $('.login-popover-wrapper');
        var email = $wrapper.find('input[name=email]').val();
        var pword = $wrapper.find('input[name=password]').val();
        var uType = $wrapper.find('input[name=loginAs]:checked').val();
				var redirectUrl = $wrapper.find('input[name=redirect]').val();
        $.ajax({
            method: 'post',
            url: '/auth/login',
            beforeSend: function() {
                //clear all errors
                $wrapper.find('.error').hide();
            },
            data: { 
							email: email, 
							password: pword, 
							_token: $('input[name=_token]').val(), 
							loginAs: uType,
							redirect: redirectUrl
						},
            dataType: 'json',
            success: function(data) {
                if (!data.status) {
                    if (typeof data.message !== 'undefined') {
                        $('.generic-error')
                            .html(data.message)
                            .show();
                    } else {
                        for (var x in data.errors) {
                            if (x === 'email') {
                                $('.email-error')
                                    .html(data.errors[x])
                                    .show();
                            }

                            if (x === 'password' || x === 'generic' || x === 'general') {
                                $('.generic-error')
                                    .html(data.errors[x])
                                    .show();
                            }
                        }
                    }
                } else {
                    location.href = data.intended;
                }
            },
        });
    }

    /** PWI CUSTOM NUMBER INPUT **/
    $.fn.pwiNumberInput = function(params) {
        var $elements = $(this);

        $elements.each(function(index, element) {
            var $control = $(element);
            var $minusButton = $control.find('button.minus');
            var $plusButton = $control.find('button.plus');
            var $input = $control.find('input[type=number]');

            // Decrement the value
            $minusButton.on('click', function() {
                if ($input.val() == '') {
                    $input.val(0);
                }
                if (parseInt($input.val()) - 1 < $input.attr('min')) {
                    return;
                }
                $input.val(parseInt($input.val()) - 1);
                $input.trigger('change');
            });

            // Increment the value
            $plusButton.on('click', function() {
                if ($input.val() == '') {
                    $input.val(0);
                }
                if (parseInt($input.val()) + 1 > $input.attr('max')) {
                    return;
                }
                $input.val(parseInt($input.val()) + 1);
                $input.trigger('change');
            });
        });
    };

    function hideAlert($form) {
        $form.find('.payment-alert').addClass('hidden');
        $form
            .find('.payment-alert .alert')
            .removeClass('alert-danger')
            .removeClass('alert-success');
        $form.find('.payment-alert .alert').html('');
    }
    function showError($form, message) {
        $form.find('.payment-alert').removeClass('hidden');
        $form
            .find('.payment-alert .alert')
            .addClass('alert-danger')
            .removeClass('alert-success');
        $form.find('.payment-alert .alert').html(message);
    }
    function showSuccess($form, message) {
        //console.log("form: ", $form);
        //console.log("payment alert: ", $form.find('.payment-alert'));
        $form.find('.payment-alert').removeClass('hidden');
        $form
            .find('.payment-alert .alert')
            .removeClass('alert-danger')
            .addClass('alert-success');
        $form.find('.payment-alert .alert').html(message);
    }
    function enableSubmitButton($button, buttonHtml) {
        $button.removeClass('disabled');
        $button.removeAttr('disabled');
        $button.html(buttonHtml);
    }
    function disableSubmitButton($button, buttonLoadingHtml) {
        $button.addClass('disabled');
        $button.attr('disabled', 'disabled');
        $button.html(buttonLoadingHtml);
    }

    function initializeDateInputs() {
        if ($('input.pickadate:not(.picker__input)').length > 0) {
            $('input.pickadate:not(.picker__input)').pickadate({
                selectYears: 20,
                formatSubmit: 'yyyy-mm-dd',
                // format: 'mmm d, yyyy'
            });
        }
    }

    function initializeTimeInputs() {
        if ($('input.pickatime:not(.picker__input)').length > 0) {
            $('input.pickatime:not(.picker__input)').pickatime({
                interval: 15,
                formatSubmit: 'HH:i',
                format: 'h:i A',
            });
        }
    }

    /** Page Load **/
    $(document).ready(function() {
        // Configure lazy loading
        var bLazy = new Blazy({
            offset: 500, // Loads images 500px before they're visible
        });

        // Automatically add CSRF token to all ajax request headers
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        });

        // Cross-browser date/time picker - HTML5 date and time
        // inputs do not work in Safari, IE, or Firefox
        initializeDateInputs();
        initializeTimeInputs();

        // USER SELECTS A NEW PLAN FROM THE DROPDOWN
        $(document).on('change', '#planId', function(event) {
            let $form = $(event.target).closest('form#orgSubscriptionForm');
            let oldPlan = $form.find('input[name=oldPlanId]').val();
            let $planSelect = $(event.target);
            let newPlan = $planSelect.val();
            let monthlyPrice = $planSelect.find('option:selected').attr('data-monthly-price');
            let annualPrice = $planSelect.find('option:selected').attr('data-annual-price');
            let $showOnPlanChange = $form.find('.show-on-plan-change');
            let frequency = $('input[name=frequency]:checked').val();

            if (monthlyPrice) {
                $('#plan-frequency-wrapper')
                    .find('.monthly-price')
                    .html(monthlyPrice);
            }
            if (annualPrice) {
                $('#plan-frequency-wrapper')
                    .find('.annual-price')
                    .html(annualPrice);
            }
            if (frequency) {
                switch (frequency) {
                    case 'annually':
                        $('#you-pay-today .amount').html(annualPrice);
                        break;
                    case 'monthly':
                        $('#you-pay-today .amount').html(monthlyPrice);
                        break;
                    default:
                        break;
                }
            }

            //console.log('1');
            //console.log('oldPlan: ', oldPlan);
            //console.log('newPlan: ', newPlan);
            if (oldPlan !== newPlan) {
                $showOnPlanChange.removeClass('hidden');
            } else {
                $showOnPlanChange.addClass('hidden');
            }
        });

        // USER SELECTS A NEW PLAN FREQUENCY
        $(document).on('change', 'input[name=frequency]', function(event) {
            let $planSelect = $('select#planId');
            let $form = $planSelect.closest('form#orgSubscriptionForm');
            let oldPlan = $form.find('input[name=oldPlanId]').val();
            let newPlan = $planSelect.val();
            let monthlyPrice = $planSelect.find('option:selected').attr('data-monthly-price');
            let annualPrice = $planSelect.find('option:selected').attr('data-annual-price');
            let $showOnPlanChange = $form.find('.show-on-plan-change');
            let frequency = $('input[name=frequency]:checked').val();

            if (monthlyPrice) {
                $('#plan-frequency-wrapper')
                    .find('.monthly-price')
                    .html(monthlyPrice);
            }
            if (annualPrice) {
                $('#plan-frequency-wrapper')
                    .find('.annual-price')
                    .html(annualPrice);
            }
            if (frequency) {
                switch (frequency) {
                    case 'annually':
                        $('#you-pay-today .amount').html(annualPrice);
                        break;
                    case 'monthly':
                        $('#you-pay-today .amount').html(monthlyPrice);
                        break;
                    default:
                        break;
                }
            }

            //console.log('2');
            //console.log('oldPlan: ', oldPlan);
            //console.log('newPlan: ', newPlan);
            if (oldPlan !== newPlan) {
                $showOnPlanChange.removeClass('hidden');
            } else {
                $showOnPlanChange.addClass('hidden');
            }
        });

        // USER CHOOSES WHICH PAYMENT METHOD TO USE
        $(document).on('change', '#whichCC', function(event) {
            var whichCC = $(event.target).val();
            var $form = $(event.target).closest('form#orgSubscriptionForm');

            if (whichCC === 'current') {
                // HIDE THE CC FORM
                $form.find('.cc-form').addClass('hidden');

                // MAKE CC INFO NOT REQUIRED
                $form.find('.cc-form input, .cc-form select').removeAttr('required');
            } else if (whichCC === 'new') {
                // SHOW THE CC FORM
                $form.find('.cc-form').removeClass('hidden');

                // MAKE CC INFO REQUIRED
                $form
                    .find('.cc-form input:not([name=billingAddress2]):not([name=saveAddress]), .cc-form select')
                    .attr('required', 'required');
            }
        });

        function submitSubscriptionUpgrade(formValues, $form, $button) {
            var buttonHtml = $button.html();
            var buttonLoadingHtml = "<i class='fa fa-cog fa-spin' aria-hidden='true'></i> Processing...";

            // Disable the button
            disableSubmitButton($button, buttonLoadingHtml);

            // Hide any old alerts
            hideAlert($form);

            $.ajax({
                url: '/org/updateSubscription',
                data: formValues,
                processData: false,
                contentType: false,
                type: 'POST',
                dataType: 'text',
            })
                .done(function(result) {
                    result = JSON.parse(result);
                    //console.log(result);
                    if (result.success) {
                        // Show the success message
                        showSuccess(
                            $form,
                            'Payment successful. Your receipt will be emailed to you. The page will now refresh.'
                        );
                        swal(
                            {
                                title: 'Success!',
                                text:
                                    'Payment successful. Your receipt will be emailed to you. The page will now refresh.',
                                type: 'success',
                                showCancelButton: false,
                                confirmButtonClass: 'btn-success',
                                confirmButtonText: 'OK',
                                closeOnConfirm: true,
                            },
                            function() {
                                // Refresh the page
                                window.location.href = '/org/settings';
                            }
                        );
                    } else {
                        // Re-enable the button
                        enableSubmitButton($button, buttonHtml);

                        // Show the error
                        swal({
                            title: 'Something went wrong!',
                            text: result.response,
                            type: 'error',
                            showCancelButton: false,
                            confirmButtonClass: 'btn-danger',
                            confirmButtonText: 'OK',
                            closeOnConfirm: true,
                        });
                    }
                })
                .fail(function() {
                    // Re-enable the button
                    enableSubmitButton($button, buttonHtml);

                    swal({
                        title: 'Something went wrong!',
                        type: 'error',
                        showCancelButton: false,
                        confirmButtonClass: 'btn-danger',
                        confirmButtonText: 'OK',
                        closeOnConfirm: true,
                    });
                });
        }

        // USER UPGRADES THEIR PLAN
        $(document).on('click', '#change-billing-frequency', function(event) {
            var $form = $('form#orgSubscriptionForm');
            var formValues = new FormData();
            var orgId = $('input#org-id').val();
            var planId = $('input#current-plan-id').val();
            var frequency = $('input[name="current-plan-frequency"]:checked').val();
            var $button = $(event.currentTarget);

            // Set the form values
            formValues.append('_token', $('meta[name="csrf-token"]').attr('content'));
            formValues.append('orgId', orgId);
            formValues.append('planId', planId);
            formValues.append('frequency', frequency);

            // Submit the request
            submitSubscriptionUpgrade(formValues, $form, $button);
        });

        $(document).on('change', 'input[name=current-plan-frequency]', function(event) {
            let $options = $('label[for^="current-plan-frequency"]');
            let $selected = $('input[name="current-plan-frequency"]:checked');
            let $label = $('label[for="' + $selected.attr('id') + '"]');
            let $saveButton = $('button#change-billing-frequency');
            let price = $label.attr('data-price');
            let $priceLabel = $('.current-plan-price');

            $options.removeClass('btn-primary').addClass('btn-default');
            $label.removeClass('btn-default').addClass('btn-primary');

            $priceLabel.html('$' + price + '/month');

            $saveButton.removeClass('hidden');
        });

        $(document).on('change', 'input[name^=upgrade-plan-frequency]', function(event) {
            let planId = $(event.currentTarget).attr('data-plan-id');
            let $options = $(
                'label[for^="upgrade-plan-frequency-monthly[' +
                    planId +
                    ']"], label[for^="upgrade-plan-frequency-yearly[' +
                    planId +
                    ']"]'
            );
            let $selected = $('input[name^="upgrade-plan-frequency"][data-plan-id=' + planId + ']:checked');
            let $label = $('label[for="' + $selected.attr('id') + '"]');
            let $upgradeButton = $('button.upgrade-plan-button[data-plan-id=' + planId + ']');
            let price = $label.attr('data-price');
            let $priceLabel = $('.upgrade-plan-price[data-plan-id=' + planId + ']');

            $options.removeClass('btn-primary').addClass('btn-default');
            $label.removeClass('btn-default').addClass('btn-primary');

            $priceLabel.html('$' + price + '/month');

            $upgradeButton.removeAttr('disabled');
        });

        $(document).on('click', '.upgrade-plan-button', function(event) {
            let $button = $(event.currentTarget);
            let $form = $('form#orgSubscriptionForm');
            let formValues = new FormData();
            let orgId = $('input#org-id').val();
            let planId = $button.attr('data-plan-id');
            let frequency = $('input[name^="upgrade-plan-frequency[' + planId + ']"]:checked').val();

            // Set the form values
            formValues.append('_token', $('meta[name="csrf-token"]').attr('content'));
            formValues.append('orgId', orgId);
            formValues.append('planId', planId);
            formValues.append('frequency', frequency);

            submitSubscriptionUpgrade(formValues, $form, $button);
        });
    });

    /*
     *
     * Set up $.colorbox for videos and photos
     *
     */

    if ($('.instagram-photos').length > 0) {
        $('.instagram-photos').colorbox({ rel: 'group1', scalePhotos: true, maxWidth: '100%' });
    }
});
$(document).ready(function() {
  var $navbar = $(".index-navbar");
  AdjustHeader();
  $(window).scroll(function() {
      AdjustHeader();
  });
  
  function AdjustHeader(){
    if ($(window).scrollTop() > 60) {
      if (!$navbar.hasClass("navbar-fixed-top")) {
        $navbar.addClass("navbar-fixed-top");
      }
    } else {
      $navbar.removeClass("navbar-fixed-top");
    }
  }
});